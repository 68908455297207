.App {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

h2 {
  font-size: 1rem;
  color: #bd2361;
  margin: 2rem 0;
  padding: 0.5rem
}

.logo {
  width: 70vw;
  height: auto
}

.content {
  text-align: center;
}